import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { ChallengeHeader, ChallengeHeadermain, SeeAllButton } from "../../../ChallengeDashboardV2/styles";
import { CardDashboard, Row1, Row2, CardContainerV2 } from "./style";
import { withRouter } from "react-router-dom";
import { surveyAnalysisIcon } from "../../../../utils/icons";
import { t } from "i18next";
import SkeletonLoder from "../../../common/skeletonLoder";

const SurveyAnalysis = (props) => {
  const { header, surveyId, SurveyAnalysis, isSurveyAnalysisLoading } = props;
  const [showMore, setShowMore] = useState(false);
  const [surveyAnalysisData, setSurveyAnalysisData] = useState([]);

  useEffect(() => {
    setSurveyAnalysisData(SurveyAnalysis.slice(0, 4));
  }, [SurveyAnalysis]);

  return (
    <React.Fragment>
      {isSurveyAnalysisLoading ? (
        <CardDashboard style={{display:"flex"}}>
          {Array.from({ length: 3 }).map((_, index) => (
            <SkeletonLoder
              key={index}
              width={"292px"}
              height={"180px"}
              style={{ margin: "0px 20px 20px 0px" }}
            />
          ))}
        </CardDashboard>
      ) : (
        surveyAnalysisData && surveyAnalysisData?.length ? (
          <React.Fragment>
            <ChallengeHeadermain>
              <div>
                <ChallengeHeader color={1} style={{ margin: '0px 0px 20px 0px', color: "rgba(0, 92, 135, 1)" }}>
                  {t("Company Survey Analysis")}
                </ChallengeHeader>
              </div>
              <div>
                {SurveyAnalysis && SurveyAnalysis.length > 4 ? (
                  <SeeAllButton
                    onClick={() => {
                      setShowMore(!showMore);
                      showMore ? setSurveyAnalysisData(SurveyAnalysis.slice(0, 4)) : setSurveyAnalysisData(SurveyAnalysis);
                    }}
                    color={"color"}
                    style={{
                      color: "rgba(0, 92, 135, 0.60)",
                      fontFamily: "Rubik",
                      fontSize: "18px",
                      fontWeight: 400,
                      lineHeight: "24px"
                    }}
                  >
                    {showMore ? t('See Less <') : t('See All >')}
                  </SeeAllButton>
                ) : ''}
              </div>
            </ChallengeHeadermain>

            <CardDashboard>
              {surveyAnalysisData.map((item) => {
                let activeStatus = (header === item.survey_name) && (surveyId === item.survey_id) ? 1 : 0;
                return (
                  <CardContainerV2
                    key={item.survey_id}
                    active={activeStatus}
                    style={{ width: '292px', minHeight: "180px", cursor: "default" }}
                  >
                    <Row1 active={activeStatus}>
                      <div className="icon">{surveyAnalysisIcon()}</div>
                      <div className="category" style={{ flexDirection: "column", alignItems: "flex-start" }}>
                        <span>{item.category_name.replace(/Survey/g, "Score")}</span>
                        <div style={{ marginTop: '10px' }}>
                          {item.total_surveys > 0 && (
                            <span style={{
                              color: "rgba(0, 92, 135, 0.60)",
                              fontFamily: "Rubik-Regular",
                              fontSize: "16px",
                              fontWeight: 400,
                              lineHeight: "20px"
                            }}>
                              Launched {item.total_surveys} Surveys
                            </span>
                          )}
                        </div>
                      </div>
                    </Row1>
                    <p className="status" style={{
                      background: item?.status?.toLowerCase().includes("risk") ? "#F4AAA9" :
                        item?.status?.toLowerCase().includes("neutral") ? "#F1C977" :
                          item?.status?.toLowerCase().includes("win") ? "#76AB78" : "NA"
                    }}>
                      {item?.status}
                      {/* {item.average_score < 41 ? "Risk" :
                        item.average_score > 40 && item.average_score < 76 ? "Neutral" :
                          item.average_score > 74 ? "Win" : "NA"} */}
                    </p>
                    <Row2 active={activeStatus}>
                      {item.total_surveys > 0 ? (
                        <div className="percentage">{Math.round(item.average_score) + "%"}</div>
                      ) : (
                        <div className="percentage">{""}</div>
                      )}
                      <span className="score" style={{
                        marginLeft: item.total_surveys > 0 ? "8px" : "0px",
                        paddingTop: item.total_surveys > 0 ? "0px" : "16px"
                      }}>
                        {item.total_surveys > 0 ? "Average Score" : "No Survey Launched"}
                      </span>
                    </Row2>
                  </CardContainerV2>
                );
              })}
            </CardDashboard>
          </React.Fragment>
        ) : null
      )}
    </React.Fragment>
  );
};

SurveyAnalysis.propTypes = {
  history: PropTypes.object,
  selectedYearInfo: PropTypes.number,
  setHeader: PropTypes.func,
  header: PropTypes.string,
  surveyReportDetails: PropTypes.array,
  surveyId: PropTypes.number,
  SurveyAnalysis: PropTypes.array,
  isSurveyAnalysisLoading: PropTypes.bool
};

export default withRouter(SurveyAnalysis);
